// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$white: #ffffff;
$off-white: #fff9ee;
$grey: #707070;

$blue: #4077db;

$blue-dark: #2c3e50;
$green: #586748;
$green-dark: #98c358;

$black: $blue-dark;

$teal: #93b7be;
$magenta: #7f79c6;
// theme-colors
$primary: $magenta;
$secondary: $green-dark;

// body
$body-bg: $white;
$body-color: $blue-dark;

// links
$link-color: $blue-dark;
$link-decoration: none;
$link-hover-color: darken($link-color, 50%);

// typography
@import url("https://fonts.googleapis.com/css2?family=Birthstone&family=Mukta:wght@200;300;400;500;600;700;800&display=swap");
$font-family-primary: "Mukta", sans-serif;
$font-family-secondary: "Birthstone", cursive;

$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$h1-font-size: $font-size-base * 3.75; // 60px
$h2-font-size: $font-size-base * 2.875; // 46px
$h3-font-size: $font-size-base * 2; // 32px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base; // 16px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 500;
$headings-color: $green-dark;

// contextual
$light: $off-white;
$dark: $green-dark;
$text-light: $white;
$text-dark: $blue-dark;

// matrix-colors
$matrix-color-primary: $black;
$matrix-color-secondary: $primary;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
