// new base file - january 2022

// override source styling: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
.search-book {
	@extend .clearfix;
	input, select {
		border: 1px solid #b8b8b8 !important;
	}
}
.search-book .tommy-zeb-wrapper * {
	font-family: $font-family-base !important;
}
.search-book .tommy-zeb-wrapper .tommy-zeb-left {
	background: $white;
	* {
		color: $green-dark;
	}
}
.search-book .tommy-zeb-wrapper .tommy-zeb-result.ng-scope {
	background: rgba($primary, 0.1);
}
.search-book .tommy-zeb-wrapper .tommy-zeb-left h1 {
	display: none;
}
.search-book .tommy-zeb-wrapper * h2 {
	font-size: 18px;
	font-weight: 700;
}
.search-book .tommy-zeb-wrapper .tommy-zeb-person-categories h2 {
	margin-top: 0 !important;
}
.search-book .tommy-zeb-wrapper .tommy-zeb-btn {
	@extend .btn;
	@extend .btn-primary;
	font-size: 20px;
	font-family: $font-family-secondary !important;
}
.search-book .tommy-zeb-wrapper .tommy-zeb-result-label.ng-binding {
	margin-bottom: 10px;
	color: $primary;
	font-size: 20px;
	font-weight: 700;
}
.search-book .tommy-zeb-wrapper .tommy-zeb-result-price.ng-binding {
	color: $secondary;
	font-weight: 700;
}

@media (min-width: 1200px) {
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-image img {
		max-width: 250px;
	}
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-details {
		width: 520px;
	}
}
@media (min-width: 980px) and (max-width: 1199px) {
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-image img {
		margin-bottom: 15px;
	}
}
@media (min-width: 768px) and (max-width: 979px) {
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-image {
		margin-right: 0;
	}
}
