// footer
.footer {
	.footer-intro {
		margin-bottom: 9vh;
		.column {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-flow: column;
			text-align: center;
			gap: 1rem;
			.logo {
				margin-bottom: 3vh;
				width: 400px;
				@include media-breakpoint-down(md) {
					max-width: 80%;
					margin: auto;
					margin-bottom: 1.5rem;
				}
			}
			.footer-text {
				a {
					color: $primary;
					font-weight: bold;
				}
				strong {
					font-size: 18px;
				}
			}
			.vocabulary-term-list {
				ul {
					display: flex;
					gap: 0.5rem;

					a {
						text-decoration: none !important;
					}

					li {
						.list-item-title {
							display: none;
						}

						i {
							background: $green-dark;
							color: $white;
							font-size: 36px;
							display: block;
							border-radius: 50%;
							display: flex;
							width: 60px;
							aspect-ratio: 1/1;
							align-items: center;
							justify-content: center;
							transition: 0.35s ease;

							&:hover {
								transform: scale(1.1);
								transition: 0.35s ease;
							}
						}
					}
				}
			}
		}
	}
	.footer-socket {
		background: $primary;
		padding: 1.25rem;
		.list {
			justify-content: center;
			align-items: center;
			gap: 0.5rem;
			li {
				color: $white;
				a {
					color: $white;
				}
				&::after {
					content: "|";
					margin-left: 0.5rem;
				}
				&:nth-last-child(1) {
					&::after {
						display: none;
					}
				}
			}
		}
	}
}
