// mini-sab
.mini-sab {
	@include media-breakpoint-down(md) {
		margin-top: -18vh;
		margin-bottom: 0vh;
		padding-bottom: 2.9vh;
		background: $green-dark;
	}
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;
		.column {
			padding-right: 0 !important;
		}
		.container-holder {
			z-index: 100;
			position: relative;
			margin: 15px 0 0 0;
			padding: 0 1rem;
			border-radius: 40px;
			background: $white;
			box-shadow: 3px 3px 6px rgba($black, 0.15);
			color: #fff;

			// below eyecatcher

			// above eyecatcher
			@include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 9vh;
			}
			@include media-breakpoint-down(md) {
				bottom: unset;
				.column {
					padding: 0;
				}
			}
			.tommy-zeb-left {
				@include media-breakpoint-down(md) {
					display: flex !important;
					flex-flow: column !important;
					margin-top: 3vh;
					margin-bottom: 3vh;
					.tommy-zeb-period {
						display: flex;
						flex-flow: column;
						margin-bottom: 1rem;
					}
				}
				> div {
					border-right: 1px solid $black;
					margin-right: 0 !important;

					@include media-breakpoint-down(md) {
						border-right: 0;
						flex-basis: 100%;
						max-width: 100%;
						border-bottom: 1px solid $black;
						padding: 0 !important;
						margin: 0 !important;
						padding: 0 !important;

						&:nth-child(1) {
							border-radius: 20px 20px 0px 0px !important;
						}
					}

					h2,
					label {
						padding: 0 15px !important;
						font-size: 14px !important;
						color: $green !important;
						margin-top: 6px !important;
					}
					button,
					input,
					select,
					option {
						color: $black;
						font-size: 17px;
						border: 0 !important;
						margin-bottom: 6px !important;
						margin: auto 15px 5px auto !important;
						text-align: left;
						&:focus {
							border: 0 !important;
						}
						@include media-breakpoint-down(md) {
							max-width: 90% !important;
						}
					}
					select {
						max-width: 95% !important;
						margin-left: 0 !important;
						margin-right: 0 !important;
						@include media-breakpoint-down(md) {
							max-width: 90% !important;
							margin: auto !important;
						}
					}
					.tommy-zeb-date-begin {
						border-right: 1px solid $black;
						margin-right: 0 !important;
						@include media-breakpoint-down(md) {
							border-right: none;
							border-bottom: 1px solid $black;
						}
					}
				}
				.tommy-zeb-update {
					all: unset !important;
					cursor: pointer !important;
					display: block !important;
					height: 100% !important;
					display: flex !important;
					align-items: center !important;
					justify-content: center !important;
					min-width: 4rem !important;
					aspect-ratio: 1/1 !important;
					position: relative;
					opacity: 0;
					&::after {
						content: "\f002";
						font-family: $font-awesome;
						font-size: 30px;
						color: $primary;
						transition: 0.35s ease;
						position: absolute;
						display: flex;
						justify-content: center;
						align-items: center;
						background: $white;
						width: 3rem;
					}
					&:hover {
						&::after {
							transform: scale(1.15);
							transition: 0.35s ease;
						}
					}
					@include media-breakpoint-down(md) {
						width: 100% !important;

						aspect-ratio: unset !important;
						margin-bottom: 1rem;
						border-radius: 0 0 20px 20px !important;
						margin-top: 1rem;
						position: relative;
						min-height: 4rem;
						height: 4rem !important;
						&::after {
							position: absolute;
						}
					}
				}
			}
		}
	}
}
