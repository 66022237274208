// main
main {
	@include media-breakpoint-down(md) {
		margin-top: -1px;
	}
}
// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	background: rgba($green-dark, 1);
	border: none;
	box-shadow: none;
	border-radius: 20px;
	.card-title,
	.card-subtitle,
	.card-description {
		color: $white;
	}
	.card-title {
		font-weight: 400;
		font-size: $h2-font-size;
	}
	.card-btn {
		background: none;
		padding: 0;
		border-radius: 0;
		font-family: $font-family-primary;
		font-weight: 600;
		color: $white;
		font-size: $h6-font-size;
		&::after {
			content: "\f08e";
			margin-left: 0.5rem;
			font-family: $font-awesome;
		}
	}
}

// images
.gallery-link {
	border-radius: 15px;
}
