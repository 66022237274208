// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $primary;
	transition: 0.5s;

	&.sticky {
		box-shadow: $shadow;
		.logo {
			opacity: 1;
			transition: 0.5s ease;
		}
	}

	.container,
	.container-fluid {
		padding: 0 1.5rem;
		.container-holder {
			> .column {
				align-items: center;
				justify-content: flex-end;
				min-height: 9vh;
				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		margin-right: auto;

		.navbar-toggler {
			background: $white;
			border-radius: 35px;
			color: $black;
			padding: 5px 15px;
		}
	}

	// logo
	.logo {
		opacity: 0;
		order: -1;
		width: 175px;
		margin: 10px 10px 10px 0;
		transition: 0.5s ease;
		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
		@include media-breakpoint-down(md) {
			opacity: 1;
			order: 1;
			max-width: 110px;
			margin: auto;
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 3;
			position: fixed;
			left: 0;
			top: 9vh;
			width: 100%;
			background: $primary;
			.navbar-nav {
				padding: 3rem 1rem;

				.nav-item {
					border-bottom: 1px solid $blue-dark;

					.nav-link {
						font-family: $font-family-primary;
						color: $white;
						font-size: 18px;
					}

					&.active {
						.nav-link {
							color: $white !important;
							font-weight: 600;
						}
					}

					.dropdown-menu {
						position: static !important;
						border: 0 !important;
						box-shadow: none !important;
						margin: 0 !important;
						padding: 0 !important;
						transform: none !important;
						background: none !important;

						&::after,
						&::before {
							display: none;
						}

						.nav-item {
							border-bottom: 0;
						}

						.nav-link {
							font-size: 16px;
							font-family: $font-family-primary;
						}
					}
				}
			}
		}
		@include media-breakpoint-up(xl) {
			position: absolute;
			margin: auto;
			left: 0;
			right: 0;
			max-width: fit-content;
			.navbar-nav {
				gap: 1rem;
				.nav-item {
					.nav-link {
						color: $white;
						font-weight: bold;
					}
					&.active,
					&:hover {
						.nav-link {
							text-decoration: 1px underline;
							text-underline-offset: 10px;
						}
					}
					.dropdown-menu.show {
						border: none;
						background: $primary;
						box-shadow: none;
						text-align: center;
						padding-bottom: 1rem;
						min-width: 10vw;
						&::after,
						&::before {
							display: none;
						}
						.nav-link {
							color: $white;
							text-decoration: none;
							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
	}
	// global social
	.global-social {
		ul {
			display: flex;
			gap: 0.5rem;
			a {
				text-decoration: none !important;
			}
			li {
				.list-item-title {
					display: none;
				}

				i {
					background: $white;
					color: $green-dark;
					font-size: 16px;
					display: block;
					border-radius: 50%;
					display: flex;
					width: 28px;
					aspect-ratio: 1/1;
					align-items: center;
					justify-content: center;
					transition: 0.35s ease;
					&:hover {
						transform: scale(1.1);
						transition: 0.35s ease;
					}
				}
			}
		}
		@include media-breakpoint-down(md) {
			order: 2;
		}
	}
	// site-switcher
	.site-switcher {
	}
}
