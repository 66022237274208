// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 60vh;
	min-height: 300px;
	max-height: 540px;
	.owl-caption {
		display: none;
	}
	.owl-dots {
		display: none;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 45vh;
	min-height: 240px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 90vh;
	}
}

section.eyecatcher {
	position: relative;
	.owl-carousel .item {
		// filter: brightness(0.5);
	}
	div.title {
		position: absolute;
		top: 50%;
		bottom: 0;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		z-index: 900;
		max-width: fit-content;
		max-height: fit-content;
		transform: translateY(-50%);
		.eyecatcher-title {
			color: $white;
			font-size: 140px;
		}
		&:nth-child(odd) {
			transform: translate(-10%, -130%);
			.eyecatcher-title {
				font-size: 85px;
			}
		}
	}
	&::after {
		content: url("/images/logo-white.svg");
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		pointer-events: none;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;
		transform: translate(-50%, -50%);
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}

section.eyecatcher:not(.large) {
	position: relative;

	&::before {
		content: "";
		width: 100%;
		height: 12vh;
		bottom: 0;
		left: 0;
		position: absolute;
		background: url("/images/repeat.svg");
		background-size: cover;
		z-index: 100;
	}
}
