// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section + .content-section,
.lead-section + .bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// home-lead-section
.home-lead-section {
	padding: 9vh 0 33vh 0;
	background: $green-dark;
	position: relative;
	&::after {
		content: "";
		width: calc(100% + 1rem);
		height: 12vh;
		bottom: -1rem;
		left: -1rem;
		position: absolute;
		background: url("/images/repeat.svg");
	}
	.container {
		.container-holder {
			max-width: 750px;
			@include media-breakpoint-down(md) {
				text-align: center;
			}
		}
		.title,
		.description {
			color: $white;
			p {
				margin-bottom: 3rem;
			}
		}
	}
	@include media-breakpoint-down(md) {
		padding-bottom: 9vh;
	}
}
// accommodation section

.accommodation-section {
	padding-bottom: 9vh;
	.container-fluid {
		padding-top: 0;
		max-width: 70%;
		margin: auto;
		.grid-items {
			margin-top: -26vh;
			margin-bottom: 3vh;
			@include media-breakpoint-down(md) {
				margin-top: -9vh;
			}
			.item {
				.card {
					background: none;
					border: none;
					.card-image {
						border-radius: 50%;
						border: 10px solid $white;
					}
					.card-body {
						text-align: center;
						position: relative;
						padding: 2rem 1rem;
						@include media-breakpoint-down(md) {
							padding: 1rem;
						}
						li {
							max-width: fit-content;
							margin: auto;
						}
						.card-title {
							font-size: $h2-font-size;
							color: $green-dark;
						}
						.card-subtitle {
							position: absolute;
							top: 0;
							right: 0;
							transform: translateY(-100%);
							display: block;
							background: $primary;
							color: $white;
							width: 150px;
							aspect-ratio: 1/1;
							display: flex;
							align-items: center;
							justify-content: center;
							overflow: hidden;
							border-radius: 50%;
							padding: 0.75rem;
							font-size: 35px;
							word-wrap: break-word;
							word-break: keep-all;
							@include media-breakpoint-down(md) {
								font-size: 20px;
								width: 125px;
							}
						}
						.card-description {
							margin-top: 1.5rem;
						}
						.card-btn {
							padding: 0;
							border-radius: 0;
							background: none;
							color: $primary;
							text-decoration: underline;
							font-family: $font-family-primary;
							font-size: 16px;
							text-align: center;
							margin-bottom: 1rem;
						}
					}
				}
			}
		}
		.read-more-link {
			margin: auto;
		}
	}
}

// home banner section
.home-banner-section {
	padding-bottom: 6vh;
	overflow: hidden;
	margin: 0 auto 6vh auto;
	.owl-stage-outer {
		overflow: visible;
	}
	.owl-carousel .owl-item {
		min-height: 75vh;
		padding: 0;

		.item {
			padding: 0;
		}
		.owl-caption-holder {
			height: 100%;
			position: relative;
			max-width: 100%;
			padding: 0;
			margin: 0 auto;
			z-index: 2;
			&::after {
				content: "";
				width: calc(100% + 1rem);
				height: 12vh;
				bottom: -1rem;
				left: -1rem;
				position: absolute;
				background: url("/images/repeat.svg");
				background-size: cover;
				z-index: 1;
			}
			.owl-caption {
				position: absolute;
				left: 10vw;
				bottom: -6vh;
				background: $green-dark;
				border: 12px solid $white;
				max-width: 18vw;
				width: 18vw;
				aspect-ratio: 1/1;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-flow: column;
				padding: 1rem;
				z-index: 2;
				@include media-breakpoint-down(md) {
					width: 90%;
					max-width: 90%;
					bottom: -6vh;
					left: 0;
					right: 0;
					margin-left: auto;
					margin-right: auto;
				}
				.owl-btn,
				.owl-subtitle {
					display: none;
				}
			}
		}
	}
	.owl-dots {
		width: 10vw;
		bottom: -1vh;
		left: 14%;
		.owl-dot {
			span {
				width: 20px;
				height: 20px;
				aspect-ratio: 1/1;
				background: $white;
			}
			&.active {
				span {
					background: $primary;
				}
			}
		}
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	.container-one-column {
		.container-holder {
			margin: 0 auto;
			text-align: center;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		max-width: 750px;
		margin: auto;
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
