// btn
.btn {
	font-family: $font-family-secondary;
	font-size: 28px;
	border-radius: 35px;
	padding: 4.5px 31.5px;
	border: 0;
	position: relative;
	overflow: hidden;
	&::before {
		content: "";
		position: absolute;
		width: 0%;
		aspect-ratio: 1/1;
		background: $white;
		filter: blur(6px);
		border-radius: 50%;
		opacity: 0.15;
		pointer-events: none;
		margin: auto;
		transform: translate(-50%, -50%);
		top: var(--mouse-y, -50%);
		left: var(--mouse-x, 50%);
		transition: 0.5s ease;
	}
	&:hover {
		&::before {
			width: 40%;
			transition: 0.5s ease;
		}
	}

	// btn-primary
	&.btn-primary {
		color: #fff;
		&:hover {
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
		}
	}

	// btn-secondary
	&.btn-secondary {
		background: $white;
		color: $primary;
		&:hover {
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}

.read-more-link a {
	@extend .btn, .btn-primary;
}
